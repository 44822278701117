import getViewport from './get-viewport'

// equivalent to CSS media queries in style.scss :
// @media only screen and (max-width: 480px) {
// https://stackoverflow.com/questions/12556115/is-max-device-width-referring-to-document-body-clientwidth
function shouldLockBodyScroll () {
  return getViewport().width <= 480
}
function lockBodyScroll (config) {
  if (config.previousBodyOverflow === null) {
    config.previousBodyOverflow = document.body.style.overflow
  }
  document.body.style.overflow = 'hidden'
}
function unlockBodyScroll (config) {
  if (config.previousBodyOverflow !== null) {
    document.body.style.overflow = config.previousBodyOverflow
  }
  config.previousBodyOverflow = null
}

export default function lockUnlockBodyScroll (config) {
  if (shouldLockBodyScroll()) {
    if (config.isIframeMainVisible) {
      lockBodyScroll(config)
    } else {
      unlockBodyScroll(config)
    }
  } else {
    unlockBodyScroll(config)
  }
}
