export default function initBridge (bridge) {
  let initDone = false

  const queue = window.FidMarquesLibraryName &&
    window[window.FidMarquesLibraryName] &&
    window[window.FidMarquesLibraryName].q
  if (queue) {
    for (let i = 0, len = queue.length; i < len; i++) {
      const args = queue[i]
      if (args[0] === 'init') {
        bridge.init(args[1])
        initDone = true
      }
    }
  }
  if (!initDone) {
    setTimeout(() => { initBridge(bridge) }, 50)
  }
}
