import './style.scss'

import { EXTENSION_POPUP, WIDGET_BRIDGE, WIDGET_IFRAME } from './utils/actors'

import baseUrl from './utils/base-url'
import baseUrlCoreApi from './utils/base-url-core-api'
import getViewport from './utils/get-viewport'
import initBridge from './utils/init-bridge'
import lockUnlockBodyScroll from './utils/lock-unlock-body-scroll'
import hasOwnProp from './utils/hasOwnProp'
import fetchPonyfill from 'fetch-ponyfill'

const { fetch } = fetchPonyfill()
const errorPrefix = '[Widget FidMarques] '

const createConfig = () => ({
  isMobile: null,
  isIframeMainVisible: false,
  previousBodyOverflow: null,
  params: null,
  points: null,
})

// holding all references to DOM elements
const createHtmlElements = () => ({
  container: null,
  containerMobile: null,

  card: null,
  img: null,
  points: null,
  suffix: null,

  iframeMain: null,
  wrapperMain: null,

  // iframeFab: null,
  // iframeCard: null,
})

let cardAssets = null
let config = createConfig()
let htmlElements = createHtmlElements()

const bridge = {
  init (params) {
    if (process.env.NODE_ENV === 'production') {
      const style = this.createStyleTag()
      document.head.appendChild(style)
    }

    this.createCard()
    this.createIframeMain()
    this.createContainer()

    htmlElements.card.addEventListener('click', this.toggleIframeMain.bind(this))
    // htmlElements.iframeFab.addEventListener('click', this.toggleIframeMain.bind(this))

    this.update(params, true)
  },

  update (params, isInit = false) {
    this.validateParams(params)

    config.params = params
    config.env = params.env || process.env.API_ENV
    config.baseUrl = baseUrl(config.env)
    this.callCoreApi()

    const { clientId, clientSecret, userId, userEmail, demoProgramId } = params
    const paramsForUrlSuffix = {
      clientId,
      clientSecret,
      demoProgramId,
      userExternalId: userId,
      userExternalEmail: userEmail,
      origin: location.origin,
    }
    const urlSuffix = this.paramsToString(paramsForUrlSuffix)
    const fullUrl = config.baseUrl + '/?' + urlSuffix

    if (htmlElements.iframeMain.src !== fullUrl) {
      config.points = null
      htmlElements.iframeMain.src = fullUrl
    }

    const classes = ['fdm__container-desktop']
    if (['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(params.corner)) {
      classes.push(`fdm__container-desktop--${params.corner}`)
    }
    htmlElements.container.className = classes.join(' ')

    const klasses = ['fdm__card']
    if (['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(params.corner)) {
      klasses.push(`fdm__card--${params.corner}`)
    }
    htmlElements.card.className = klasses.join(' ')

    if (params.breakpointWidth && params.mobileContainerId) {
      htmlElements.containerMobile = document.getElementById(params.mobileContainerId)
      if (!htmlElements.containerMobile) {
        (console.error || console.log)(errorPrefix + 'cound not find HTML element with ID "' + params.mobileContainerId + '"')
      } else {
        htmlElements.containerMobile.classList.add('fdm__container-mobile')
        window.addEventListener('resize', this.resize)
        this.resize()
      }
    } else {
      this.resetContainerMobile()
    }

    this.updateCard()

    window.postMessage({
      sender: WIDGET_BRIDGE,
      recipient: EXTENSION_POPUP,
      message: isInit ? 'initialised' : 'updated',
    }, window.location.origin)
  },

  resetContainerMobile () {
    window.removeEventListener('resize', this.resize)
    if (htmlElements.containerMobile) {
      htmlElements.containerMobile.classList.remove('fdm__container-mobile')
      htmlElements.container.appendChild(htmlElements.card)
      htmlElements.containerMobile = null
    }
  },

  resetContainer () {
    if (htmlElements.container) {
      htmlElements.container.remove()
      htmlElements.container = null
    }
  },

  remove () {
    this.resetContainerMobile()
    this.resetContainer() // must be called after resetContainerMobile
    config = createConfig()
    htmlElements = createHtmlElements()
    // window.removeEventListener('message', receiveMessage)
    // window.removeEventListener('resize', lockUnlockBodyScrollBinded)

    window.postMessage({
      sender: WIDGET_BRIDGE,
      recipient: EXTENSION_POPUP,
      message: 'removed',
    }, window.location.origin)
  },

  resize () {
    if (!config.params.breakpointWidth) { return }
    if (!config.params.mobileContainerId) { return }

    config.isMobile = getViewport().width < config.params.breakpointWidth
    if (config.isMobile) {
      htmlElements.containerMobile.appendChild(htmlElements.card)
    } else {
      htmlElements.container.appendChild(htmlElements.card)
    }

    // bridge.updateCard()
  },

  validateParams ({ clientId, clientSecret, userId, userEmail, demoProgramId }) {
    return this.validateParam(clientId, 'clientId')
  },
  validateParam (param, name) {
    if (!param) {
      throw new Error(errorPrefix + 'missing parameter ' + name)
    }
  },
  paramsToString (params) {
    const arr = []
    for (const key in params) {
      if (hasOwnProp(params, key) && (typeof params[key] !== 'undefined')) {
        arr.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      }
    }
    return arr.join('&')
  },

  createStyleTag () {
    return this.createTag({
      tagName: 'LINK',
      attributes: {
        type: 'text/css',
        rel: 'stylesheet',
        href: __CSS_URL__,
      },
    })
  },
  createTag ({ tagName, attributes, klasses, styles }) {
    let idx
    let key
    const tag = document.createElement(tagName)
    for (key in attributes) {
      if (hasOwnProp(attributes, key)) {
        tag.setAttribute(key, attributes[key])
      }
    }
    if (typeof klasses === 'string') { klasses = [klasses] }
    for (idx in klasses) {
      if (hasOwnProp(klasses, idx)) {
        tag.classList.add(klasses[idx])
      }
    }
    for (key in styles) {
      if (hasOwnProp(styles, key)) {
        tag.style[key] = styles[key]
      }
    }
    return tag
  },
  genericCardUrl () {
    if (config.points === null) {
      return 'https://cdn1.purchease.com/assets/sdk/generic/card.png'
    } else {
      return 'https://cdn1.purchease.com/assets/sdk/generic/card-pts.png'
    }
  },
  callCoreApi () {
    const paramsForUrlSuffix = {
      client_id: config.params.clientId,
      demo_program_id: config.params.demoProgramId,
    }
    const urlSuffix = this.paramsToString(paramsForUrlSuffix)
    fetch(`${baseUrlCoreApi(config.env)}/web_client/sdk_fdm/card_assets?${urlSuffix}`, {
      headers: {
        'Accept': 'application/json',
      },
    }).then((response) => response.json()).then((data) => {
      cardAssets = data
      this.updateCard()
    })
  },
  createCard () {
    htmlElements.card = this.createTag({
      tagName: 'DIV',
      klasses: 'fdm__card',
    })
    htmlElements.wrapper = this.createTag({
      tagName: 'DIV',
      klasses: ['fdm__card__wrapper-points'],
    })

    htmlElements.img = this.createTag({
      tagName: 'IMG',
      klasses: 'fdm__card__img',
    })
    htmlElements.img.addEventListener('error', (e) => {
      htmlElements.img.src = this.genericCardUrl()
    })

    htmlElements.points = this.createTag({ tagName: 'SPAN', klasses: 'fdm__card__points' })
    htmlElements.suffix = this.createTag({ tagName: 'SPAN', klasses: 'fdm__card__suffix' })
    htmlElements.wrapper.appendChild(htmlElements.points)
    htmlElements.wrapper.appendChild(htmlElements.suffix)
    htmlElements.card.appendChild(htmlElements.img)
    htmlElements.card.appendChild(htmlElements.wrapper)
  },
  updateCard () {
    if (cardAssets) {
      if (cardAssets.card_assets_urls) {
        if (config.points === null) {
          htmlElements.img.src = cardAssets.card_assets_urls.card_asset_3x_url || cardAssets.card_assets_urls.card_asset_2x_url || cardAssets.card_assets_urls.card_asset_url
          // htmlElements.img.srcset = `${cardAssets.card_assets_urls.card_asset_url} 1x, ${cardAssets.card_assets_urls.card_asset_2x_url} 2x, ${cardAssets.card_assets_urls.card_asset_3x_url} 3x`
        } else {
          htmlElements.img.src = cardAssets.card_assets_urls.card_pts_asset_3x_url || cardAssets.card_assets_urls.card_pts_asset_2x_url || cardAssets.card_assets_urls.card_pts_asset_url
          // htmlElements.img.srcset = `${cardAssets.card_assets_urls.card_pts_asset_url} 1x, ${cardAssets.card_assets_urls.card_pts_asset_2x_url} 2x, ${cardAssets.card_assets_urls.card_pts_asset_3x_url} 3x`
        }
      } else {
        htmlElements.img.src = this.genericCardUrl()
      }
    }

    if (config.pointsColor) {
      htmlElements.wrapper.style = `color: ${config.pointsColor};`
    }

    if (config.points === null) {
      htmlElements.points.textContent = ''
      htmlElements.suffix.textContent = ''
    } else {
      htmlElements.points.textContent = config.points
      htmlElements.suffix.textContent = config.points >= 2 ? 'pts' : 'pt'
    }
  },
  createIframeMain () {
    htmlElements.wrapperMain = this.createTag({
      tagName: 'DIV',
      klasses: ['fdm__wrapper-iframe'],
    })
    htmlElements.iframeMain = this.createTag({
      tagName: 'IFRAME',
      klasses: ['fdm__iframe'],
      attributes: {
        allow: 'camera',
      },
    })
    htmlElements.iframeMain.textContent = 'You need a Frames Capable browser to view this content.'
    htmlElements.wrapperMain.appendChild(htmlElements.iframeMain)
  },
  createContainer () {
    htmlElements.container = document.createElement('DIV')
    // htmlElements.container.style.display = 'none'
    document.body.appendChild(htmlElements.container)
    htmlElements.container.appendChild(htmlElements.card)
    htmlElements.container.appendChild(htmlElements.wrapperMain)
  },

  drawIframeMain () {
    if (config.isIframeMainVisible) {
      htmlElements.container.classList.add('fdm__wrapper-iframe--enabled')
    } else {
      htmlElements.container.classList.remove('fdm__wrapper-iframe--enabled')
    }
    lockUnlockBodyScroll(config)

    // call to iframe
    htmlElements.iframeMain.contentWindow.postMessage({
      sender: WIDGET_BRIDGE,
      recipient: WIDGET_IFRAME,
      function: 'updateIsIframeOpen',
      params: { status: config.isIframeMainVisible },
    }, config.baseUrl)
  },
  hideIframeMain () {
    config.isIframeMainVisible = false
    this.drawIframeMain()
  },
  showIframeMain () {
    config.isIframeMainVisible = true
    this.drawIframeMain()
  },
  toggleIframeMain () {
    config.isIframeMainVisible = !config.isIframeMainVisible
    this.drawIframeMain()
  },
}

const iframeMethods = {
  addClass ({ element, klass }) {
    bridge[element].classList.add(klass)
  },
  removeClass ({ element, klass }) {
    bridge[element].classList.remove(klass)
  },
  hideIframeMain () {
    bridge.hideIframeMain()
  },
  showIframeMain () {
    bridge.showIframeMain()
  },
  toggleIframeMain () {
    bridge.toggleIframeMain()
  },
  setPoints ({ points, color }) {
    config.points = points
    config.pointsColor = color
    bridge.updateCard()
  },
}

function receiveMessage (event) {
  // console.log('bridge#receiveMessage', event)

  // call from iframe
  if (event.origin === config.baseUrl) {
    if (event.data && event.data.function) {
      const functionToExec = event.data.function
      if (typeof iframeMethods[functionToExec] === 'function') {
        iframeMethods[functionToExec](event.data.params)
      }
    }
  }

  // call from browser extension
  if (event.origin === window.location.origin) {
    if (
      event.data &&
      event.data.recipient === WIDGET_BRIDGE
    ) {
      switch (event.data.function) {
        case 'PING':
          window.postMessage({
            sender: WIDGET_BRIDGE,
            recipient: EXTENSION_POPUP,
            message: 'PONG',
            env: config.env,
            widgetParameters: config.params,
          }, window.location.origin)
          break
        default: {
          const fn = bridge[event.data.function]
          if (fn && typeof fn === 'function') {
            bridge[event.data.function](event.data.params)
          } else {
            // TODO: airbrake
            (console.error || console.log)(errorPrefix + 'missing function ' + event.data.function)
          }
        }
      }
    }
  }
}
window.addEventListener('message', receiveMessage)

// const lockUnlockBodyScrollBinded = () => { lockUnlockBodyScroll(config) }
// window.addEventListener('resize', lockUnlockBodyScrollBinded)

initBridge(bridge)

if (process.env.NODE_ENV !== 'production') {
  window.bridge = bridge
}

export default bridge
