export default (env) => {
  switch (env) {
    case 'development':
      return 'http://127.0.0.1:3000'
    case 'staging':
      return 'https://staging-widget.fidmarques.com'
    case 'production':
      return 'https://widget.fidmarques.com'
    default:
      // TODO: airbrake
  }
}
