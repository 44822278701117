export default (env) => {
  switch (env) {
    case 'development':
      return 'http://localhost:15700'
    case 'staging':
      return 'https://staging-api.purchease.com'
    case 'production':
      return 'https://api.purchease.com'
    default:
      // TODO: airbrake
  }
}
